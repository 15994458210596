<template>
  <div class="wrap__contents agent-commissions-history">
    <div class="toolbar">
      <div class="toolbar__items searchbar">
        <search
          :translations="translations"
          :filter-by="filterBy"
          :date-by="dateBy"
          @trigger="$_trigger"
          @refresh="search"
          @clear="$_trigger({clear:1})"
        />
      </div>

      <div class="toolbar__items buttons">
        <button
          class="btn--confirm"
          @click="modals.requestWithdrawCommission.status = 1"
        >
          {{ translate(translations, 'request') }}
        </button>
      </div>
    </div>

    <div class="wrap__inner">
      <CheckDataList
        v-if="isDataLoading || isEmpty"
        :is-loading="isDataLoading"
        :is-empty="isEmpty"
      />

      <app-table
        v-else
        :headers="headers"
        :data-list="commissionTxnList"
        :data-count="commissionTxnCount"
        :is-loading="showDataLoading"
        @trigger="$_trigger"
        @trigger:sort="$_trigger"
        @trigger:page="$_trigger"
      >
        <template #[`body.id`]="{ index }">
          {{ $_showNumber(index, commissionTxnCount) }}
        </template>

        <template #[`body.login_id`]="{ item }">
          {{ item.agent.login_id }}
        </template>

        <template #[`body.login_name`]="{ item }">
          {{ item.agent.login_name }}
        </template>

        <template #[`body.category`]="{ item }">
          {{
            translateDB(item.category, {
              en: 'name',
              kr: 'kor'
            })
          }}
        </template>

        <template #[`body.personal_auto_deposit`]="{ item }">
          {{ item.details['personal-auto-deposit'] | FormatAmount('whole') }}
        </template>

        <template #[`body.referral_auto_deposit`]="{ item }">
          {{ item.details['referral-auto-deposit'] | FormatAmount('whole') }}
        </template>

        <template #[`body.withdraw`]="{ item }">
          {{ item.details['withdraw'] | FormatAmount('whole') }}
        </template>

        <template #[`body.amount`]="{ item }">
          {{ item.amount | FormatAmount('whole') }}
        </template>
      </app-table>
    </div>

    <modal
      v-if="modals.requestWithdrawCommission.status"
      :title="translate(translations, 'request commission')"
      :activate="modals.requestWithdrawCommission.status"
      class="modal--create-code"
      :width="500"
      @activate="modals.requestWithdrawCommission.status = $event"
    >
      <template slot="body">
        <div class="form__item">
          <div class="form__label">
            {{ translate(translations, 'amount') }}
          </div>

          <div class="form__data">
            <div class="input-wrap">
              <input-amount v-model="modals.requestWithdrawCommission.data.amount" />
            </div>
          </div>
        </div>
      </template>

      <template slot="footer">
        <button
          class="btn--confirm"
          @click="handleSubmitRequestCommission"
        >
          <span>
            {{ translate(translations, 'create') }}
          </span>
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

// lib
import _isEmpty from 'lodash/isEmpty'
import _pickBy from 'lodash/pickBy'
import _get from 'lodash/get'

// components
import Modal from '@/components/base/Modal'
import Search from '@/components/base/search/Search'
import CheckDataList from '@/components/base/CheckDataList'
import AppTable from '@/components/base/AppTable'

// mixins
import { getListStatus } from '@/assets/js/mixins/common/GetListStatus'

// filters
import FormatAmount from '@/assets/js/filters/FormatAmount'

// helpers
import { replaceChar } from '@/assets/js/common/Helpers'

// translation
import translations from '@/assets/js/translations/agent-detail-commission'

export default {
  name: 'AgentCommissionHistory',

  components: {
    Modal,
    AppTable,
    Search,
    CheckDataList
  },

  filters: {
    FormatAmount
  },

  mixins: [
    getListStatus
  ],

  data () {
    return {
      translations,

      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false,

      modals: {
        requestWithdrawCommission: {
          status: false,

          data: {
            amount: undefined
          },

          reset () {
            this.status = false
            this.data.amount = undefined
          }
        }
      },

      dateBy: [
        {
          value: 'created_at',
          display: 'created at'
        }
      ]
    }
  },

  computed: {
    ...mapGetters('agent-commission-transactions', {
      commissionTxnList: 'commissionTxnList'
    }),

    ...mapState('agent-commission-transactions', {
      // commissionTxnList: 'list',
      commissionTxnCount: 'count',
      commissionTypes: 'types'
    }),

    filterBy () {
      const list = [
        {
          value: 'login_id',
          display: 'login_id',
          is_hidden: this.$route.name === 'agent-details-page'
        }
      ]

      return list.filter(x => !x.is_hidden)
    },

    headers () {
      const translate = str => this.translate(translations, str)

      return [
        {
          text: translate('no'),
          value: 'id',
          sortable: true,
          width: 50
        },
        {
          text: translate('created_at'),
          value: 'created_at',
          sortable: true
        },
        {
          text: translate('login_id'),
          value: 'login_id',
          sortable: true,
          is_hidden: this.$route.name === 'agent-details-page'
        },
        {
          text: translate('login_name'),
          value: 'login_name',
          sortable: true,
          is_hidden: this.$route.name === 'agent-details-page'
        },
        {
          text: this.typesByName['personal-auto-deposit'],
          value: 'personal_auto_deposit',
          align: 'right',
          sortable: true
        },
        {
          text: this.typesByName['referral-auto-deposit'],
          value: 'referral_auto_deposit',
          align: 'right',
          sortable: true
        },
        {
          text: translate('withdraw'),
          value: 'withdraw',
          align: 'right',
          sortable: true
        },
        {
          text: translate('total'),
          value: 'amount',
          align: 'right',
          sortable: true
        }
      ]
    },

    typesByName () {
      const list = this.commissionTypes

      const config = { en: 'name', kr: 'kor' }

      return list.reduce((acc, curr) => {
        return { ...acc, [curr.name]: replaceChar(this.translateDB(curr, config), '-', ' ') }
      }, {})
    }
  },

  watch: {
    $route (route) {
      this.showDataLoading = true
      this.search()
    }
  },

  async created () {
    await this.getCommissionTypes()
    await this.search()
  },

  methods: {
    ...{ _isEmpty },

    ...mapActions('agent-commission-transactions', {
      getCommissionTxns: 'get',
      getCommissionTypes: 'getTypes',
      requestWithdrawCommission: 'requestWithdraw'
    }),

    async search (query = {}) {
      this.params = query || this.params

      this.isEmpty = false

      if (!this.showDataLoading) {
        this.isDataLoading = true
      }

      await this.getCommissionTxns(_pickBy({
        agent_id: this.$route.params.id,
        sort_by: 'id',
        sort: 'desc',
        ...this.$route.query,
        ...query
      }, Boolean))

      this.isDataLoading = false
      this.showDataLoading = false
      this.isEmpty = _isEmpty(this.commissionTxnList)
    },

    handleSubmitRequestCommission () {
      const success = async () => {
        try {
          const modal = this.modals.requestWithdrawCommission

          await this.requestWithdrawCommission({
            amount: modal.data.amount
          })

          modal.reset()

          return this.$showAlert({ type: 'success' })
        } catch (error) {
          const errorName = _get(error, 'response.data.name')
          const errorMessage = _get(error, 'response.data.msg')

          if (errorName === 'ValidationError') {
            return this.$showAlert({ type: 'error', text: errorMessage })
          }

          if (errorName === 'REQUEST_ERROR') {
            if (errorMessage === 'PENDING_REQUEST') {
              return this.$showAlert({
                type: 'error',
                text: 'you_still_have_pending_request'
              })
            }
          }

          console.log(error)
          this.$showAlert({ type: 'error' })
        }
      }

      return this.$showAlert({
        type: 'prompt',
        title: 'modify',
        success
      })
    }
  }
}
</script>
