export default {
  from: '에서',
  to: '까지',

  all: '전체',
  losing: '루징',
  rolling: '롤링',
  manual: '수동처리',

  admin_name: '관리자',
  status: '상태',
  memo: '메모',
  domain: '도메인',
  ip: '아이피',
  no: '번호',
  created_at: '등록일자',
  login_id: '아이디',
  login_name: '닉네임',
  type: '구분',
  old_balance: '이전금액',
  new_balance: '배팅금액',
  amount: '신청금액',

  commission: '보유수수료',
  commission_history: '수수료내역',
  request: '출금 요청',
  request_commission: '수수료 출금 신청',
  create: '출금 요청',
  total: '전체',
  withdraw: '출금'
}
