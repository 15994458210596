var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap__contents agent-commissions-history"},[_c('div',{staticClass:"toolbar"},[_c('div',{staticClass:"toolbar__items searchbar"},[_c('search',{attrs:{"translations":_vm.translations,"filter-by":_vm.filterBy,"date-by":_vm.dateBy},on:{"trigger":_vm.$_trigger,"refresh":_vm.search,"clear":function($event){return _vm.$_trigger({clear:1})}}})],1),_c('div',{staticClass:"toolbar__items buttons"},[_c('button',{staticClass:"btn--confirm",on:{"click":function($event){_vm.modals.requestWithdrawCommission.status = 1}}},[_vm._v(" "+_vm._s(_vm.translate(_vm.translations, 'request'))+" ")])])]),_c('div',{staticClass:"wrap__inner"},[(_vm.isDataLoading || _vm.isEmpty)?_c('CheckDataList',{attrs:{"is-loading":_vm.isDataLoading,"is-empty":_vm.isEmpty}}):_c('app-table',{attrs:{"headers":_vm.headers,"data-list":_vm.commissionTxnList,"data-count":_vm.commissionTxnCount,"is-loading":_vm.showDataLoading},on:{"trigger":_vm.$_trigger,"trigger:sort":_vm.$_trigger,"trigger:page":_vm.$_trigger},scopedSlots:_vm._u([{key:"body.id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.$_showNumber(index, _vm.commissionTxnCount))+" ")]}},{key:"body.login_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.agent.login_id)+" ")]}},{key:"body.login_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.agent.login_name)+" ")]}},{key:"body.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.translateDB(item.category, { en: 'name', kr: 'kor' }))+" ")]}},{key:"body.personal_auto_deposit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("FormatAmount")(item.details['personal-auto-deposit'],'whole'))+" ")]}},{key:"body.referral_auto_deposit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("FormatAmount")(item.details['referral-auto-deposit'],'whole'))+" ")]}},{key:"body.withdraw",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("FormatAmount")(item.details['withdraw'],'whole'))+" ")]}},{key:"body.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("FormatAmount")(item.amount,'whole'))+" ")]}}],null,true)})],1),(_vm.modals.requestWithdrawCommission.status)?_c('modal',{staticClass:"modal--create-code",attrs:{"title":_vm.translate(_vm.translations, 'request commission'),"activate":_vm.modals.requestWithdrawCommission.status,"width":500},on:{"activate":function($event){_vm.modals.requestWithdrawCommission.status = $event}}},[_c('template',{slot:"body"},[_c('div',{staticClass:"form__item"},[_c('div',{staticClass:"form__label"},[_vm._v(" "+_vm._s(_vm.translate(_vm.translations, 'amount'))+" ")]),_c('div',{staticClass:"form__data"},[_c('div',{staticClass:"input-wrap"},[_c('input-amount',{model:{value:(_vm.modals.requestWithdrawCommission.data.amount),callback:function ($$v) {_vm.$set(_vm.modals.requestWithdrawCommission.data, "amount", $$v)},expression:"modals.requestWithdrawCommission.data.amount"}})],1)])])]),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn--confirm",on:{"click":_vm.handleSubmitRequestCommission}},[_c('span',[_vm._v(" "+_vm._s(_vm.translate(_vm.translations, 'create'))+" ")])])])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }