
<!--
@USE AS:
import AppTable from '@/components/base/AppTable'

components: {
  AppTablesu
},

computed: {
  headers () {
    return [
      {
        text: 'no',
        value: 'id',
        sortable: true,
        width: 10
      },
      {
        text: 'name',
        value: 'name',
        sortable: true
      },
      {
        text: 'active',
        value: 'active',
        sortable: true
      },
      {
        text: '',
        value: 'action',
        is_hidden: 1
      }
    ]
  }
}

<app-table
  :headers="headers"
  :data-list="miniGamesList"
  :data-count="miniGamesCount"
  :is-loading="isDataLoading"
  :item-class="itemClass"
  @trigger="trigger"
  @trigger:sort="trigger"
  @trigger:page="trigger"
>
  <template #[`body.name`]="{ item, index }">
    <span @click="test">
      {{ item.name }}
    </span>
  </template>
</app-table>

HEADERS API
text          required    Header text
value         required    Header value. Also used for sorting
sortable      optional    Is column sortable
width         optional    Column width
style         optional    Column style
align         optional    Text alignment
is_hidden     optional    Determines whether the column is visible

THEAD/TBODY EXAMPLES
<template #[`head.name`]="{ item }">
  Custom Name
</template>

// Listeners aren't applied on template. Make span/div and attach listeners/attributes
<template #[`head.name`]="{ item }">
  <span
    @click="clickMe"
    class="is-amount"
  >
    Custom Name
  </span>
</template>

// Use the following to prevent clashing with click:row
<template #[`head.name`]="{ item }">
  <span @click.stop="clickMe">
    Custom Name
  </span>
</template>
-->

<template>
  <tbl :data-loading="isLoading">
    <template #head>
      <tbl-td
        v-for="(currHead, headKey) in formattedHeaders"
        :key="headKey"
        :name="currHead.className"
        :sort="currHead.sortable"
        :style="{
          width: `${currHead.width === 0 ? '' : currHead.width + 'px !important'}`,
          'user-select': 'none'
        }"
        @click="currHead.sortable && sort(currHead.value)"
      >
        <slot
          :name="`head.${currHead.value}`"
          :item="currHead"
          :index="headKey"
        >
          {{ currHead.text }}
        </slot>
      </tbl-td>
    </template>

    <template #body>
      <tbl-row
        v-for="(currData, dataKey) in dataList"
        :key="dataKey"
        :class="itemClass({ item: currData, key: dataKey })"
        @click.native="$emit('click:row', { item: currData, index: dataKey })"
      >
        <tbl-td
          v-for="(currHead, headKey) in formattedHeaders"
          :key="headKey"
          :name="currHead.className"
          :is-html="currHead.isHtml"
          :logs="currHead.logs && currHead.logs({ item: currData })"
          :style="currHead.style && currHead.style({ item: currData })"
          :class="{
            ...(currHead.align && { [`align-${currHead.align}`]: true} ),
            ...(currHead.classList && currHead.classList({ item: currData }))
          }"
        >
          <slot
            :name="`body.${currHead.value}`"
            :item="currData"
            :index="dataKey"
          >
            {{ currData[currHead.value] }}
          </slot>
        </tbl-td>
      </tbl-row>
    </template>

    <template #footer>
      <pagination
        :value="$route.query.page || 1"
        :rows="$route.query.rows || 50"
        :page="$route.query.page || 1"
        :total="dataCount"
        @input="paginate"
      />
    </template>
  </tbl>
</template>

<script>
// components
import Pagination from '@/components/base/Pagination'

// libs
import _pickBy from 'lodash/pickBy'
import _isNil from 'lodash/isNil'

// helpers
import { replaceChar } from '@/assets/js/common/Helpers'
const trimObject = obj => _pickBy(obj, x => !_isNil(x))

export default {
  name: 'AppTable',

  components: {
    Pagination
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false
    },

    headers: {
      type: Array,
      default: () => ([])
    },

    dataList: {
      type: Array,
      default: () => ([])
    },

    dataCount: {
      type: Number,
      default: 0
    },

    itemClass: {
      type: [Function],
      default: val => val
    }
  },

  computed: {
    formattedHeaders () {
      const headers = this.headers

      const newHeaders = []

      headers.forEach(header => {
        if (header.is_hidden) {
          return
        }

        newHeaders.push({
          ...header,
          className: replaceChar(header.value, '_', '-')
        })
      })

      return newHeaders
    }
  },

  methods: {
    trigger (payload) {
      const params = JSON.parse(JSON.stringify(this.$route.query))

      this.$emit('trigger', trimObject({
        sort: params.sort,
        sort_by: params.sort_by,
        page: params.page,
        rows: params.rows,
        ...payload
      }))
    },

    sort (sortBy) {
      const params = JSON.parse(JSON.stringify(this.$route.query))

      const payload = {
        sort: params.sort === 'asc' ? 'desc' : 'asc',
        sort_by: sortBy
      }

      this.$emit('trigger:sort', payload)

      this.trigger(payload)
    },

    paginate (page) {
      const params = JSON.parse(JSON.stringify(this.$route.query))
      const queryPage = Number(params.page)
      const newPage = Number(page)

      if (queryPage === newPage) {
        return
      }

      const payload = {
        page: newPage,
        rows: params.rows
      }

      this.$emit('trigger:page', payload)

      this.trigger(payload)
    }
  }
}
</script>
