export default {
  from: 'から',
  to: 'まで',

  all: '全体',
  losing: '負け',
  rolling: '回転',
  manual: '手動処理',

  admin_name: '管理者',

  //= table
  no: 'ナンバー',
  created_date: '登録日時',
  old_commission: '以前のコミッション',
  amount: '保有金額',
  new_commission: '新規コミッション',
  type: '区分',
  memo: 'メモ',
  admin_ip: 'アドミン IP',
  admin: '管理者'
}
