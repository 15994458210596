export default {
  from: 'from',
  to: 'to',

  all: 'all',
  losing: 'losing',
  rolling: 'rolling',
  manual: 'manual',

  admin_name: 'admin name',

  //= table
  no: 'no',
  created_date: 'created',
  old_commission: 'old commission',
  amount: 'amount',
  new_commission: 'new commission',
  type: 'type',
  memo: 'memo',
  admin_ip: 'admin ip',
  admin: 'admin'
}
